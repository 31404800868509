import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/styles.scss'
import i18n from "@/i18n/i18n";
import gsapMixin from '@/mixins/gsap'

createApp(App)
  .mixin(gsapMixin)
  .use(i18n)
  .use(store)
  .use(router)
  .mount('#app')
