import {createI18n} from "vue-i18n";

const messages = {
  en: {
    message: {
      home: 'Home',
      offers: 'Предложения',
      delivery: 'Доставка',
      team: 'Команда',
      reviews: 'Отзывы',
      contacts: 'Контакты'
    }
  },
  ru: {
    message: {
      home: 'Главная',
      offers: 'Предложения',
      delivery: 'Доставка',
      team: 'Команда',
      reviews: 'Отзывы',
      contacts: 'Контакты'
    }
  }
}

const i18n = createI18n({
  locale: 'ru', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
})

export default i18n
