import i18n from "@/i18n/i18n";

const nav = [
  {
    link: '/',
    text: i18n.global.t('message.home')
  },
  {
    link: '/offers',
    text: i18n.global.t('message.offers')
  },
  {
    link: '/delivery',
    text: i18n.global.t('message.delivery')
  },
  {
    link: '/team',
    text: i18n.global.t('message.team')
  },
  // {
  //   link: '/reviews',
  //   text: i18n.global.t('message.reviews')
  // },
  {
    link: '/contacts',
    text: i18n.global.t('message.contacts')
  },
]

export default nav
